import React, { useState, useEffect, useContext, useRef } from 'react';
import { Link, useNavigate } from "react-router-dom";
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { InputText } from "primereact/inputtext";
import { Button } from 'primereact/button';
import { Accordion, AccordionTab } from 'primereact/accordion';
import Header from "../Header/Header";
import './Albaran_Purchase.css'; // CSS externo para estilos
import AlbaranService from '../../Services/AlbaranService';
import SedeContext from "../../Services/SedeContext";
import { Dialog } from 'primereact/dialog';
import { Toast } from 'primereact/toast';
import { MultiSelect } from 'primereact/multiselect';

export default function Albaran_Purchase() {
    const toastRef = useRef(null);
    const { sede } = useContext(SedeContext);
    const [albarans, setAlbarans] = useState([]);
    const [expandedRows, setExpandedRows] = useState(null);
    const [loading, setLoading] = useState(true);
    const navigate = useNavigate();
    const [pdfDialogVisible, setPdfDialogVisible] = useState(false);
    const [pdfSrc, setPdfSrc] = useState(null);
    const [selectedEstados, setSelectedEstados] = useState([]);
    const [estados, setEstados] = useState([]);
    const [first, setFirst] = useState(0);
    const [rows, setRows] = useState(10);
    const [globalFilter, setGlobalFilter] = useState(null);
    const [loadingButtons, setLoadingButtons] = useState({});


    useEffect(() => {
        const handleResize = () => {
            document.documentElement.classList.toggle('mobile', window.innerWidth <= 768);
        };

        window.addEventListener('resize', handleResize);
        handleResize(); // Set initial state
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    useEffect(() => {
        if (!sede) {
            navigate('/');
        }

        const fetchEstados = async () => {
            try {
                const estadosData = await AlbaranService.getEstados();
                setEstados(estadosData);
                const allEstadoIds = estadosData.map(estado => estado.id);
                setSelectedEstados(allEstadoIds);
            } catch (error) {
                console.error('Error fetching estados:', error);
            }
        };

        fetchEstados();
    }, [sede, navigate]);

    useEffect(() => {
        const fetchAlbarans = async () => {
            try {
                setLoading(true);
                const data = await AlbaranService.getAllAlbarans(selectedEstados);
                setAlbarans(data.albarans || []);
            } catch (error) {
                console.error('Error fetching albarans:', error);
            } finally {
                setLoading(false);
            }
        };

        if (selectedEstados.length > 0) {
            fetchAlbarans();
        }
    }, [selectedEstados]);

    const handleEstadoChange = async (e) => {
        const estados = Array.isArray(e.value) ? e.value : [e.value];
        setSelectedEstados(estados);
        try {
            const data = await AlbaranService.getAllAlbarans(estados);
            setAlbarans(data.albarans || []);
        } catch (error) {
            console.error('Error fetching filtered albarans:', error);
        }
    };


    const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);

    const AlbaranPdf = async (id) => {
        try {
            const pdfBlob = await AlbaranService.getAlbaranPdf(id);
            if (pdfBlob) {
                const pdfUrl = URL.createObjectURL(pdfBlob);

                if (isMobile) {
                    // Si es móvil, descargar el PDF
                    const link = document.createElement('a');
                    link.href = pdfUrl;
                    link.setAttribute('download', `Albaran-${id}.pdf`);
                    document.body.appendChild(link);
                    link.click();
                    document.body.removeChild(link);
                } else {
                    // Si es escritorio, mostrar el PDF en un modal
                    setPdfSrc(pdfUrl);
                    setPdfDialogVisible(true);
                }
            }
        } catch (error) {
            console.error('Error al obtener el PDF:', error);
        }
    };
    

    const AlbaranPedido = async (id) => {
        setLoadingButtons((prevState) => ({ ...prevState, [id]: 'pedido' })); // Marca el pedido como cargando
        try {
            const success = await AlbaranService.crearPedido(id);
            if (success) {
                toastRef.current.show({
                    severity: 'success',
                    summary: 'Éxito',
                    detail: `Pedido creado exitosamente para el albarán: ${id}`,
                    life: 3000
                });
            } else {
                toastRef.current.show({
                    severity: 'error',
                    summary: 'Error',
                    detail: `No se pudo crear el pedido para el albarán: ${id}`,
                    life: 3000
                });
            }
            const data = await AlbaranService.getAllAlbarans(selectedEstados);
            setAlbarans(data.albarans || []);
        } catch (error) {
            toastRef.current.show({
                severity: 'error',
                summary: 'Error',
                detail: `Error al crear el pedido: ${error.message}`,
                life: 3000
            });
        } finally {
            setLoadingButtons((prevState) => ({ ...prevState, [id]: null })); // Quita el estado de carga
        }
    };

    const AlbaranReprocesar = async (id) => {
        setLoadingButtons((prevState) => ({ ...prevState, [id]: 'reprocesar' })); // Marca el reprocesado como cargando
        try {
            const success = await AlbaranService.reprocesar(id);
            if (success) {
                toastRef.current.show({
                    severity: 'success',
                    summary: 'Éxito',
                    detail: `Albarán reprocesado exitosamente: ${id}`,
                    life: 3000
                });
            }
            const data = await AlbaranService.getAllAlbarans(selectedEstados);
            setAlbarans(data.albarans || []);
        } catch (error) {
            toastRef.current.show({
                severity: 'error',
                summary: 'Error',
                detail: `Error al reprocesar el albarán: ${error.message}`,
                life: 5000
            });
        } finally {
            setLoadingButtons((prevState) => ({ ...prevState, [id]: null })); // Quita el estado de carga
        }
    };

    const AlbaranEliminar = async (id) => {
        setLoadingButtons((prevState) => ({ ...prevState, [id]: 'eliminar' })); // Marca la eliminación como cargando
        try {
            const success = await AlbaranService.eliminar(id);
            if (success) {
                toastRef.current.show({
                    severity: 'success',
                    summary: 'Éxito',
                    detail: `Albarán eliminado exitosamente: ${id}`,
                    life: 3000
                });
            }
            const data = await AlbaranService.getAllAlbarans(selectedEstados);
            setAlbarans(data.albarans || []);
        } catch (error) {
            toastRef.current.show({
                severity: 'error',
                summary: 'Error',
                detail: `Error al eliminar el albarán: ${error.message}`,
                life: 5000
            });
        } finally {
            setLoadingButtons((prevState) => ({ ...prevState, [id]: null })); // Quita el estado de carga
        }
    };


    const rowExpansionTemplate = (data) => {
        return (
            <div className="p-3">
                <DataTable value={data.conceptes}>
                    <Column field="concepte" header="Concepto" className="titolConcepte" sortable filter></Column>
                    <Column field="quantitat" header="Cantidad" sortable filter></Column>
                    <Column field="preu" header="Precio" sortable filter></Column>
                    <Column field="descompte" header="DTO %" sortable filter></Column>
                    <Column field="import" header="Importe" sortable filter></Column>
                </DataTable>
            </div>
        );
    };

    const allowExpansion = (rowData) => {
        return rowData.conceptes.length > 0;
    };

    const AlbaranViewBody = (rowData) => {
        return (
            <>
                <span className="pi pi-eye" style={{ cursor: 'pointer' }} onClick={() => AlbaranPdf(rowData.id)}></span>
            </>
        );
    };

    const renderActionButtons = (rowData) => {
        return (
            <div className="flex gap-2">
                <Button
                    icon="pi pi-eye"
                    onClick={() => AlbaranPdf(rowData.id)}
                    className="p-button-info"

                />
                {rowData.idEstado === 2 || rowData.idEstado === 6 ? (
                    <Button

                        icon={loadingButtons[rowData.id] === 'pedido' ? 'pi pi-spin pi-spinner' : 'pi pi-shopping-cart'}
                        onClick={() => AlbaranPedido(rowData.id)}
                        className="p-button-success"
                    />
                ) : (
                    <Button

                        icon={loadingButtons[rowData.id] === 'pedido' ? 'pi pi-spin pi-spinner' : 'pi pi-shopping-cart'}
                        className="p-button-secondary"
                        disabled
                    />
                )}
                {rowData.idEstado === 5 ? (
                    <Button

                        icon={loadingButtons[rowData.id] === 'reprocesar' ? 'pi pi-spin pi-spinner' : 'pi pi-refresh'}
                        onClick={() => AlbaranReprocesar(rowData.id)}
                        className="p-button-warning"
                    />) : (
                    <Button

                        icon={loadingButtons[rowData.id] === 'reprocesar' ? 'pi pi-spin pi-spinner' : 'pi pi-refresh'}
                        className="p-button-secondary"
                        disabled
                    />
                )}
                <Button

                    icon={loadingButtons[rowData.id] === 'eliminar' ? 'pi pi-spin pi-spinner' : 'pi pi-trash'}
                    onClick={() => AlbaranEliminar(rowData.id)}
                    className="p-button-danger"
                />

            </div>
        );
    };


    const header = (
        <div className="table-header">
            <span className="p-input-icon-left">
                <i className="pi pi-search" />
                <InputText
                    type="search"
                    onInput={(e) => setGlobalFilter(e.target.value)}
                    placeholder="Buscar en la tabla..."
                />
            </span>
        </div>
    );

    if (loading) {
        return <div>Cargando...</div>;
    }

    return (
        <div>
            <Toast ref={toastRef} />
            <div className="Albarans_container">
                <Header username={sede} />
                <div className="Albarans_contents">
                    <div className="Albaran_scan">
                        <Link to="/Albaran_scan">
                            Escanear albarán <span className="pi pi-arrow-right"></span>
                        </Link>
                    </div>
                    <div className="Albaran_filter">
                        <MultiSelect
                            id="estadoSelect"
                            value={selectedEstados}
                            options={estados}
                            onChange={handleEstadoChange}
                            optionLabel="nombre"
                            optionValue="id"
                            placeholder="Selecciona estados"
                            display="chip"
                            filter
                            style={{ marginLeft: '1rem', width: '100%', maxWidth: '20rem' }}
                        />
                    </div>
                    <div className="Albarans_dades_mobil">
                        <Accordion multiple expandIcon="pi pi-plus" collapseIcon="pi pi-minus">
                            {albarans.map((albara) => (
                                <AccordionTab key={albara.id} header={
                                    <>
                                        <span className="Albarans_tab_title">ALBARAN </span>
                                        <span className="Albarans_tab_id">{albara.albaran}</span>
                                    </>
                                }>
                                    <div className="flex flex-column gap-2">
                                        <label htmlFor="nombreEmpleado">Empleado</label>
                                        <InputText disabled id="nombreEmpleado" value={albara.nombreEmpleado} />
                                    </div>
                                    <div className="flex flex-column gap-2">
                                        <label htmlFor="estado">Estado</label>
                                        <InputText disabled id="estado" value={albara.estado} />
                                    </div>
                                    <div className="flex flex-column gap-2">
                                        <label htmlFor="obra">OBRA</label>
                                        <InputText disabled id="obra" value={albara.obra} />
                                    </div>
                                    <div className="flex flex-column gap-2">
                                        <label htmlFor="client">CLIENTE</label>
                                        <InputText disabled id="client" value={albara.client} />
                                    </div>
                                    <div className="flex flex-column gap-2">
                                        <label htmlFor="data">FECHA</label>
                                        <InputText disabled id="data" value={albara.data} />
                                    </div>
                                    <div className="flex flex-column gap-2">
                                        <label htmlFor="idFactura">Digi Id</label>
                                        <InputText disabled id="idFactura" value={albara.idFactura} />
                                    </div>
                                    <div className="flex flex-row gap-2">
                                        <div className="Albarans_veure Albarans_veure_imatge" onClick={() => AlbaranPdf(albara.id)}>
                                            <span className="pi pi-eye"></span> Albarán
                                        </div>
                                        <div className="Albarans_veure Albarans_veure_detall">
                                            <Link to={`/Albaran_detail/${albara.id}`}>
                                                Ver detalle
                                            </Link>
                                        </div>
                                        <div className="Albarans_veure Albarans_veure_pedido">
                                            <Button
                                                label="Crear Pedido"
                                                icon="pi pi-shopping-cart"
                                                onClick={() => AlbaranPedido(albara.id)}
                                                className="p-button-success"
                                            />
                                        </div>
                                    </div>
                                </AccordionTab>
                            ))}
                        </Accordion>
                    </div>
                    <div className="Albarans_dades_tablet">
                        <div className="card" style={{ width: '100%' }}>
                            <DataTable
                                expandedRows={expandedRows}
                                value={albarans}
                                rowExpansionTemplate={rowExpansionTemplate}
                                onRowToggle={(e) => setExpandedRows(e.data)}
                                dataKey="id"
                                paginator
                                rows={rows}
                                first={first}
                                onPage={(e) => setFirst(e.first)}
                                totalRecords={albarans.length}
                                globalFilter={globalFilter} s
                                header={header}
                                stripedRows
                                responsiveLayout="stack"
                                breakpoint="960px"
                                style={{ width: '100%' }}
                            >
                                <Column expander={allowExpansion} />
                                <Column field="nombreEmpleado" header="Empleado" />
                                <Column field="estado" header="Estado" sortable filter />
                                {/* <Column field="id" header="Albaran" sortable filter /> */}
                                <Column field="albaran" header="Albaran" sortable filter />
                                <Column field="obra" header="Obra" sortable filter />
                                <Column field="client" header="Cliente" sortable filter />
                                <Column field="proveidor" header="Proveedor" sortable filter />
                                <Column field="data" header="Fecha" sortable filter />
                                <Column field="idFactura" header="Digi Id" />
                                <Column
                                    header="Acción"
                                    body={renderActionButtons}
                                />
                                <Column header="" body={AlbaranViewBody} />
                            </DataTable>
                        </div>
                    </div>
                </div>
            </div>
            <Dialog header="PDF del Albarán" visible={pdfDialogVisible} style={{ width: '50vw' }} onHide={() => setPdfDialogVisible(false)}>
    {pdfSrc && <iframe src={pdfSrc} style={{ width: '100%', height: '80vh' }} title="PDF del Albarán"></iframe>}
        </Dialog>

        </div>
    );
}

import React, { useState, useEffect, useContext } from 'react';
import { Link, useNavigate } from "react-router-dom";
import { Accordion, AccordionTab } from 'primereact/accordion';
import { DataTable } from 'primereact/datatable';
import { InputText } from "primereact/inputtext";
import { Column } from 'primereact/column';
import Header from "../Header/Header";
import './Albarans.css';
import AlbaranService from '../../Services/AlbaranService';
import SedeContext from "../../Services/SedeContext";
import { Dialog } from 'primereact/dialog';
import { MultiSelect } from 'primereact/multiselect';
import { isMobile } from 'react-device-detect';  


export default function Albarans() {
    const { sede } = useContext(SedeContext);
    const [albarans, setAlbarans] = useState([]);
    const [expandedRows, setExpandedRows] = useState(null);
    const [loading, setLoading] = useState(true);
    const navigate = useNavigate();
    const [pdfSrc, setPdfSrc] = useState(null);
    const [pdfDialogVisible, setPdfDialogVisible] = useState(false);
    const [selectedEstados, setSelectedEstados] = useState([]); // Estados seleccionados para el filtro
    const [estados, setEstados] = useState([]);
    const dniTrabajador = localStorage.getItem('dni');
    const LocalSede = localStorage.getItem('LocalSede');

    useEffect(() => {
        if (!sede) {
            navigate('/');
        }

        const fetchEstados = async () => {
            try {
                const estadosData = await AlbaranService.getEstados();
                setEstados(estadosData);

                // Selecciona todos los estados al inicio
                const allEstadoIds = estadosData.map(estado => estado.id);
                setSelectedEstados(allEstadoIds);
            } catch (error) {
                console.error('Error fetching estados:', error);
            }
        };

        fetchEstados();
    }, [sede, navigate]);

    useEffect(() => {
        const fetchAlbarans = async () => {
            try {
                if (!Array.isArray(selectedEstados)) {
                    console.error('selectedEstados should be an array');
                    return;
                }
                setLoading(true);
                const data = await AlbaranService.getAllAlbarans(selectedEstados);
                setAlbarans(data.albarans || []);
            } catch (error) {
                console.error('Error fetching albarans:', error);
            } finally {
                setLoading(false);
            }
        };

        // Solo ejecuta si hay estados seleccionados
        if (selectedEstados.length > 0) {
            fetchAlbarans();
        }
    }, [selectedEstados]);


    const handleEstadoChange = async (e) => {
        // Asegúrate de que e.value sea siempre un array
        const estados = Array.isArray(e.value) ? e.value : [e.value];

        // Actualizar el estado de los estados seleccionados
        setSelectedEstados(estados);

        try {
            // Llamar al servicio con los estados seleccionados
            const data = await AlbaranService.getAllAlbarans(estados);
            setAlbarans(data.albarans || []); // Manejar el caso cuando no hay datos
        } catch (error) {
            console.error('Error fetching filtered albarans:', error);
        }
    };
    
    const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);

    const AlbaranPdf = async (id) => {
        try {
            const pdfBlob = await AlbaranService.getAlbaranPdf(id);
            if (pdfBlob) {
                const pdfUrl = URL.createObjectURL(pdfBlob);

                if (isMobile) {
                    // Si es móvil, descargar el PDF
                    const link = document.createElement('a');
                    link.href = pdfUrl;
                    link.setAttribute('download', `Albaran-${id}.pdf`);
                    document.body.appendChild(link);
                    link.click();
                    document.body.removeChild(link);
                } else {
                    // Si es escritorio, mostrar el PDF en un modal
                    setPdfSrc(pdfUrl);
                    setPdfDialogVisible(true);
                }
            }
        } catch (error) {
            console.error('Error al obtener el PDF:', error);
        }
    };

    const rowExpansionTemplate = (data) => {
        return (
            <div className="p-3">
                <DataTable value={data.conceptes}>
                    <Column field="concepte" header="Concepto" className="titolConcepte"></Column>
                    <Column field="quantitat" header="Cantidad"></Column>
                    <Column field="preu" header="Precio"></Column>
                    <Column field="descompte" header="DTO %"></Column>
                    <Column field="import" header="Importe"></Column>
                </DataTable>
            </div>
        );
    };

    const allowExpansion = (rowData) => {
        return rowData.conceptes.length > 0;
    };

    const AlbaranViewBody = (rowData) => {
        return <><span className="pi pi-eye" style={{ cursor: 'pointer' }} onClick={function (e) { AlbaranPdf(rowData.id); }}></span></>;
    };

    if (loading) {
        return <div>Cargando...</div>;
    }
    const handleRemoveDni = () => {
        localStorage.removeItem('dni');
        window.location.reload();
    };


    return (
        <div className="Albarans_container">
            <Header username={sede} />
            <div className="Albarans_contents">
                <div className="Albaran_scan">
                    <Link to="/Albaran_scan">
                        Escanear albarán <span className="pi pi-arrow-right"></span>
                    </Link>
                </div>


                <div className="Albaran_filter">
                    <MultiSelect
                        id="estadoSelect"
                        value={selectedEstados}
                        options={estados}
                        onChange={handleEstadoChange}
                        optionLabel="nombre"
                        optionValue="id"
                        placeholder="Selecciona estados"
                        display="chip"
                        filter
                        style={{ marginLeft: '1rem', width: '100%', maxWidth: '20rem' }}
                    />
                    <div className="Albaran_legend">
                        {dniTrabajador ? (
                            <>
                                <span style={{ marginLeft: '20px' }}>
                                    Mostrando albaranes para trabajador: <strong>{dniTrabajador}</strong>
                                </span>
                                <Link
                                    to="#"
                                    onClick={handleRemoveDni}
                                    className="p-link p-link-danger"
                                    style={{ marginLeft: '10px' }}
                                >
                                    Ver albaranes por sede
                                </Link>
                            </>
                        ) : (
                            <span style={{ marginLeft: '20px' }}>
                                Mostrando albaranes para sede: <strong>{sede}</strong>
                            </span>
                        )}
                    </div>

                </div>
                <div className="Albarans_dades">

                    <div className="Albarans_dades_mobil card">
                        <Accordion activeIndex={0} expandIcon="pi pi-plus" collapseIcon="pi pi-minus">
                            {albarans.map((albara) => (
                                <AccordionTab key={albara.id} header={<>
                                    <span className="Albarans_tab_title">ALBARAN </span>
                                    <span className="Albarans_tab_id">{albara.albaran}</span>
                                </>}>
                                    <div className="flex flex-column gap-2">
                                        <label htmlFor="username">Estado</label>
                                        <InputText disabled id="obra" value={albara.estado} />
                                    </div>
                                    <div className="flex flex-column gap-2">
                                        <label htmlFor="username">OBRA</label>
                                        <InputText disabled id="obra" value={albara.obra} />
                                    </div>
                                    <div className="flex flex-column gap-2">
                                        <label htmlFor="username">CLIENTE</label>
                                        <InputText disabled id="client" value={albara.client} />
                                    </div>
                                    <div className="flex flex-column gap-2">
                                        <label htmlFor="username">FECHA</label>
                                        <InputText disabled id="data" value={albara.data} />
                                    </div>
                                    <div className="flex flex-row gap-2">
                                        <div className="Albarans_veure Albarans_veure_imatge" onClick={function (e) {
                                            AlbaranPdf(albara.id);
                                        }}>
                                            <span className="pi pi-eye"></span> Albarán
                                        </div>
                                        <div className="Albarans_veure Albarans_veure_detall">
                                            <Link to={`/Albaran_detail/${albara.id}`}>
                                                Ver detalle
                                            </Link>
                                        </div>
                                    </div>
                                </AccordionTab>
                            ))}
                        </Accordion>
                    </div>
                    <div className="Albarans_dades_tablet card">
                        <DataTable
                            expandedRows={expandedRows}
                            value={albarans}
                            rowExpansionTemplate={rowExpansionTemplate}
                            onRowToggle={(e) => setExpandedRows(e.data)}
                            dataKey="id">
                            <Column expander={allowExpansion} style={{ width: '5rem' }} />
                            <Column field="estado" header="Estado" />
                            <Column field="albaran}" header="Albaran" />
                            <Column field="obra" header="Obra" />
                            <Column field="client" header="Cliente" />
                            <Column field="proveidor" header="Proveedor" />
                            <Column field="data" header="Fecha" />
                            <Column header="" body={AlbaranViewBody} />
                        </DataTable>
                    </div>
                </div>
            </div>
            <Dialog header="PDF del Albarán" visible={pdfDialogVisible} style={{ width: '50vw' }} onHide={() => setPdfDialogVisible(false)}>
    {pdfSrc && <iframe src={pdfSrc} style={{ width: '100%', height: '80vh' }} title="PDF del Albarán"></iframe>}
            </Dialog>
        </div>

    );
}